import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ImageText from "../elements/imagetext.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <ImageText src="../kuangyi.jpg" mdxType="ImageText">
  <div>
    I work in the intersection of <b>play, social inclusion, and data</b>.{" "}
    <br /> <br />
    My work often use playfulness, contrast, interaction and data, to present different perspectives, to challenge stereotypes and preconceptions, and open up spaces for nuances. I advocate for storytelling from first person perspective and plural narration. Instead of “design for others”, I design as others. 
    <br /> <br />
    Personally, I am quirky, queer, playful, logical, and multicultured.
    <br />
  </div>
    </ImageText>
    <br />
    <br />
    <h6>{`Exhibitions`}</h6>
    <div>
  2024 <a href="/doyousee">"Do you see what i see"</a>, Klokgebouw, Dutch Design
  Week 2024, Eindhoven, The Netherlands
    </div>
    <div>
  2024 <a href="/doyousee">"Do you see what i see"</a>, V2_, Rotterdam, The
  Netherlands
    </div>
    <div>
  2024 <a href="/weave">"Weave"</a>, Beeld & Geluid, Hilversum, The Netherlands
    </div>
    <div>
  2023 <a href="/weave">"Weave"</a>, Microlab, Dutch Design Week 2023,
  Eindhoven, The Netherlands
    </div>
    <br />
    <br />
    <h6>{`Talk/Workshop`}</h6>
    <div>
  2024 Designer, "Unbox" workshop, International Transdisciplinarity Conference
  2024
    </div>
    <div>2024 Speaker, queer code meetup, Creative Coding Utrecht</div>
    <br />
    <br />
    <h6>{`Education`}</h6>
    <div>
  2021-2023, Eindhoven University of Technology (the Netherlands) - MSc
  Industrial Design
  <br />
  2015-2017, The Australian National University (Australia) - BSc Infomation
  Technology
    </div>
    <br />
    <br />
    <h6>{`Experience`}</h6>
    <div>
  2024-Present{" "}
  <a href="https://www.moeilijkedingen.nl/">Bureau Moeilijke Dingen</a> (the
  Netherlands) - Creative Technologist
  <br />
  2023-2024{" "}
  <a href="https://www.unusualcollaborations.com/">
    Center for Unusual Collaboration
  </a>{" "}
  (the Netherlands) - Play Designer
  <br />
  2023-2023 Eindhoven University of Technology (the Netherlands) - UX Engineer
  <br />
  2022-2022 Design School Kolding (Denmark) - Erasmus+ Internship at Lab for
  Play and Design
  <br />
  2021-2021 <a href="https://www.rea-group.com/">REA Group</a> (Australia) -
  Associate Product Designer
  <br />
  2018-2021 <a href="https://www.rea-group.com/">REA Group</a> (Australia) - UI
  Developer
  <br />
  2016-2017 <a href="https://www.xero.com/">Xero</a> (Australia) - Software
  Developer Intern
    </div>
    <br />
    <br />
    <h6>{`Award`}</h6>
    <div>
  2022 finalist, <a href="/nebula">"Nebula"</a>, The Interaction Design
  Association (IxDA)
  <br />
  2022 finalist, <a href="/nebula">"Nebula"</a>, The most innovative students of
  the Netherlands
    </div>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      